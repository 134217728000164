import { type ThemeConfig } from "@/lib/antd";

export const themeConfigColors = {
  primary: "#6B22BE",
  secondary: "#5502B4",
  success: "#52c41a",
  processing: "#1677ff",
  error: "#C83333",
  background: "#F2F2F2",
  transparent: "transparent",
  siderBg: "#080808",
  primaryText: "#080808",
  secondaryText: "#393939",
};

const themeConfig: ThemeConfig = {
  token: {
    fontSize: 16,
    colorPrimary: themeConfigColors.primary,
    colorError: themeConfigColors.error,
    colorText: themeConfigColors.primaryText,
    colorTextSecondary: themeConfigColors.secondaryText,
    colorSuccess: themeConfigColors.success,
    fontFamily: "Inter Tight, sans-serif",
    controlHeight: 41,
  },
  components: {
    Tabs: {
      itemActiveColor: "#6B22BE",
      verticalItemMargin: "0",
      cardPadding: "8px 16px"
    },
    Form: {
      verticalLabelPadding: "0 0 4px",
      controlHeight: 41,
    },
    Input: {
      // colorBgContainer: themeConfigColors.background,
      // colorText: '#FCFCFC',
      // colorTextPlaceholder: '#808080',
      // colorBorder: '#333333',
      // hoverBorderColor:themeConfigColors.background,
      // activeBorderColor:themeConfigColors.background,
      // activeShadow:"0 0 3px "+themeConfigColors.background,
      borderRadius: 8,
      colorError: themeConfigColors.error,
      colorErrorBorderHover: themeConfigColors.error,
    },
    Card: {
      colorTextHeading: themeConfigColors.primaryText,
      colorBgContainer: themeConfigColors.background,
      
    },
    Typography: {
      colorText: themeConfigColors.primaryText,
      colorTextSecondary: themeConfigColors.secondaryText,
    },
    Layout: { siderBg: themeConfigColors.siderBg },
    Checkbox: {
      colorBorder: "#3c3c3c",
      colorBgContainer: themeConfigColors.transparent,
      lineWidth: 2,
      paddingXS: 15,
      controlInteractiveSize: 20,
    },
    Button: {
      // colorBgContainer: themeConfigColors.primary,
      borderRadius: 8,
      colorBorder: themeConfigColors.primary,
      colorText: themeConfigColors.primary,
      colorPrimaryText: "white",
      // colorBgContainerDisabled: "grey",
      // colorTextDisabled: "white",
      // boxShadow:"4px 4px 8px 0px #AA81DA",
      padding: 12,
      paddingInline: "24px",
      paddingBlock: "12px",
      controlHeight: 48,
      defaultBorderColor: "#ffffff",
      defaultHoverBg:
        "linear-gradient(154.92deg, #F5F5F5 15.82%, #EDEAF0 83.93%)",
      defaultHoverBorderColor: "#ffffff",
      defaultShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      defaultBg: "linear-gradient(154.92deg, #F5F5F5 15.82%, #EDEAF0 83.93%)",
      // padding:"12px 24px 12px 24px"
    },
    // box-shadow: 4px 4px 8px 0px var(--ColorSecondarySecondary50);
    Divider: {
      // colorTextHeading: "#595959",
      fontSize: 16,
      // colorSplit: "#595959",
      colorBorder: "#CECECE",
      colorBorderBg: "#CECECE",
      margin: 24,
    },
    Menu: {
      itemBorderRadius: 8,
      padding: 16,
      itemColor: "#FFFFFF",
      colorText: "#FFFFFF",
      // itemHoverBg: "#14123d",
      darkItemHoverBg: "#041617",
      darkItemSelectedBg: "#0b3232",
    },

    // Table: {
    //   headerBg: '#edeef0'
    // }
  },
};

export default themeConfig;
